import React, { FC } from 'react'
import { Helmet } from 'react-helmet'

interface ISeoProps {
  title: string
  description: string
  imagePath?: string
  pathname: string
  article?: any
}

const baseUrl = 'https://jonathanmadelaine.com'
const defaultImagePath = '/twittercard.png'
const twitterUsername = 'j_a_madelaine'
const defaultLanguage = 'en'

export const Seo: FC<ISeoProps> = ({ title, description, imagePath, pathname, article }) => {
  const image = `${baseUrl}${imagePath || defaultImagePath}`
  const url = `${baseUrl}${pathname || ''}`

  const isHomePage = url === baseUrl

  return (
    <Helmet
      htmlAttributes={{
        lang: defaultLanguage,
      }}
      title={title}
      titleTemplate={isHomePage ? '%s' : '%s | Jonathan Madelaine'}
    >
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:alt" content={title} />
      <meta property="og:type" content={article ? 'article' : 'website'} />
      {article && article.createdAt && (
        <meta property="article:published_time" content={article.createdAt} />
      )}
      {article && article.updatedAt && (
        <meta property="article:modified_time" content={article.updatedAt} />
      )}
      {article && article.author && <meta property="article:author" content={article.author} />}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterUsername} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta property="twitter:image:alt" content={title} />
    </Helmet>
  )
}

export default Seo
